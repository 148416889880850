<template>
  <div class="container-fluid">
    <div class="row">
      <sidebar></sidebar>
      <nav-header></nav-header>

      <main role="main" class="col-12 col-sm-9 col-md-10 ml-sm-auto col-lg-10">
        <sidebar-sm></sidebar-sm>
        <breadcrumb :items="pageData.breadcrumb"></breadcrumb>
        <!-- CALENDAR -->
        <section class="short-term-section p-5">
          <div class="bg-light b-30 p-5 shadow-1 row">
            <div v-if="quiz && quiz.questions" class="col-12">
              <div v-for="(question, $q) of quiz.questions"
                   class="question-item">
                <div class="question-text mb-4">
                  {{ $q + 1 }}. {{ question.question }}
                </div>
                <ul v-if="question.type.question_type === 'ერთი პასუხი'" class="answers-list">
                  <li @click="handleSingleAnswer(question, answer)"
                      v-for="(answer, $a) of question.answers"
                      :class="['answer-item', {'answered-bg': getAnsweredId(question) === answer.answerID }]">
                    {{ getLetter($a + 1) }}. {{ answer.answer }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-12">
              <div class="text-center">
                <button @click="handleSubmit"
                        class="btn btn-primary-red">{{ $t('short-term.submit-answers') }}</button>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  </div>

</template>

<script>
import breadcrumb from '../components/page/breadcrumb';
import sidebar from '../components/navbar/sidebar';
import navHeader from '../components/navbar/nav-header';
import pageTitle from '../components/page/page-title';
import sidebarSm from '../components/navbar/sidebar-sm';
import PayOnlineModal from '../components/pay-online-modal';
import ShortTermCourseResource from '../api/shortTermCourseResource';

const resource = new ShortTermCourseResource();

export default {
  name: 'short-term-quiz',

  components: { PayOnlineModal, breadcrumb, sidebar, navHeader, pageTitle, sidebarSm },

  computed: {
    pageData() {
      return {
        title: this.$t('short-term.title'),
        breadcrumb: [{
          icon: '',
          title: this.$t('short-term.title'),
          link: '/',
          isActive: false,
        },{
          icon: '',
          title: this.$t('short-term.course-details'),
          link: '/courses/' + this.$route.params.subjectId,
          isActive: false,
        },{
          icon: '',
          title: this.$t('short-term.quiz'),
          link: '/courses/' + this.$route.params.subjectId + '/quiz',
          isActive: true,
        }],
      };
    }
  },

  data() {
    return {
      quiz: null,
      answers: [],
      result: {
        hasPassed: null,
        canTakeAgain: null
      }
    }
  },

  mounted() {
    this.loadQuiz()
  },

  methods: {
    displaySucceed() {
      this.$swal({
        title: "გილოცავთ",
        text: 'თქვენ წარმატებით ჩააბარეთ ქვიზი',
        type: "success",
        showCancelButton: true,
        closeOnConfirm: true,
        closeOnCancel: true,
        confirmButtonText: 'სერთიფიკატის გადმოწერა',
        cancelButtonText: 'დახურვა'
      }).then((result) => {
        if(result && result.isConfirmed) {
          // TODO
          alert('xz')
        }
      });
    },

    displayFailed() {
      if (this.result.canTakeAgain) {
        this.$swal({
          title: "",
          text: 'სამწუხაროდ, თქვენ ვერ ჩააბარეთ ქვიზი, ქვიზის ხელმეორედ გავლა შეგიძლიათ 72 საათის განმავლობაში',
          type: "warning",
          showCancelButton: true,
          closeOnConfirm: true,
          closeOnCancel: true,
          confirmButtonText: 'ქვიზის ხელახლა გავლა',
          cancelButtonText: 'დახურვა'
        }).then((result) => {
          if(result && result.isConfirmed) {
            window.location.reload()
          }
        });
      } else {
        this.helpers.notifyWarningMessage('სამწუხაროდ, თქვენ ვერ ჩააბარეთ ქვიზი')
      }
    },

    handleSubmit() {
      resource.submitAnswers(this.$route.params.subjectId, this.answers)
        .then(resp => {
          this.result = {
            hasPassed: resp.data.data.has_passed,
            canTakeAgain: resp.data.data.can_take_again
          }

          if (this.result.hasPassed)
            this.displaySucceed()
          else
            this.displayFailed()
        })
        .catch(err => {
          this.helpers.notifyErrorMessage(err)
        })
    },

    getAnsweredIndex(question) {
      return this.answers.map(o => o.question_id).indexOf(question.questionID);
    },

    getAnsweredId(question) {
      const index = this.getAnsweredIndex(question);

      return index > -1 ? this.answers[index].answer : null;
    },

    handleSingleAnswer(question, answer) {
      const index = this.getAnsweredIndex(question);
      if (index > -1) {
        const tmpArray = Object.assign([], this.answers);
        tmpArray[index] = {
          question_id: question.questionID,
          answer: answer.answerID
        };

        this.answers = tmpArray;
        return;
      }

      this.answers.push({
        question_id: question.questionID,
        answer: answer.answerID
      });
    },

    getLetter(num){
      return String.fromCharCode(num + 64);
    },

    loadQuiz() {
      resource.quiz(this.$route.params.subjectId)
        .then(resp => {
          this.quiz = resp.data.data
        })
        .catch(err => {
          this.helpers.notifyErrorMessage(err)
        })
    }
  }
};
</script>

<style scoped>
  .answered-bg {
    background-color: rgba(0, 0, 0, .1);
  }

  .question-item {
    margin-bottom: 30px;
  }

  .question-text {
    font-size: 16px;
    font-weight: bold;
  }

  .answer-item {
    font-size: 16px;
    cursor: pointer;
    padding: 6px 12px;
    border-radius: 10px;
    border: 1px solid #a6a6a6;
    margin-bottom: 6px;
  }
</style>
